import {inject} from 'vue';
import {PRISMIC_CLIENT} from '~/app/keys';
import {useLogger, useLocale, useRoute, useSpanWrap, usePrismicRedirect} from '#imports';
import {callWithNuxt, useNuxtApp} from '#app';
import type {PrismicDocument} from '@prismicio/types';

/**
 * Retrieve document from Prismic repository.
 * @param documentType  Document type.
 * @param uid           Document UID.
 */
export function usePrismicDocument(documentType: string, uid?: string) {
    const nuxtApp = useNuxtApp();
    const logger = useLogger();
    const client = inject(PRISMIC_CLIENT);
    const route = useRoute();
    const {locale} = useLocale();

    const {fetchRedirect} = usePrismicRedirect(route.path, locale.value);

    const fetchDocument = async (): Promise<PrismicDocument> => {
        try {
            const getPrismicDocument = () =>
                uid ? client!.getByUID(documentType, uid, {lang: locale.value}) : client!.getSingle(documentType, {lang: locale.value});
            return await useSpanWrap(getPrismicDocument, {httpMethod: 'prismic.get', kind: 'prismic', name: `[prismic] ${documentType}`});
        } catch (err: any) {
            logger.info(
                `Could not retrieve document from Prismic with parameters: [${documentType}, ${locale.value}, ${uid}]. Error message=${err.message}. Trying redirection...`,
            );
        }
        // If the document does not exist, check for redirect or throw 404
        return await callWithNuxt(nuxtApp, fetchRedirect);
    };

    return {
        fetchDocument,
    };
}
